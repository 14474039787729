import React from 'react';
import { Link } from 'gatsby';
import Layout from 'src/components/layout/main';
import SEO from 'src/components/seo';
import Container from 'src/components/base/container';
import Section from 'src/components/base/section';
import Button from 'src/components/base/button';
import Typography from 'src/components/base/typography';
import { ImageContain } from 'src/components/base/image';

import logo1 from 'src/images/logos/blackicon.png'
import logo2 from 'src/images/logos/black linear.png'
import logo3 from 'src/images/logos/black text only.png'
import logo4 from 'src/images/logos/ll grey icon.png'
import logo5 from 'src/images/logos/ll grey stacked.png'
import logo6 from 'src/images/logos/ll light grey icon.png'
import logo7 from 'src/images/logos/ll light grey text only.png'
import logo8 from 'src/images/logos/black stacked.png'
import logo9 from 'src/images/logos/blackicon.png'
import logo10 from 'src/images/logos/ll grey linear.png'
import logo11 from 'src/images/logos/ll grey text only.png'
import logo12 from 'src/images/logos/ll light grey linear.png'



const NotFoundPage = () => (
    <Layout page="/logos">
        <SEO title="Laurium Labs Logos" />
        <Container>
            <Section padding="4rem">
                <ImageContain src={logo1} alt="logo1" style={{ maxWidth: 500, maxHeight: 100, marginBottom: 50 }} />
                <ImageContain src={logo2} alt="logo1" style={{ maxWidth: 500, maxHeight: 100, marginBottom: 50 }} />
                <ImageContain src={logo3} alt="logo1" style={{ maxWidth: 500, maxHeight: 100, marginBottom: 50 }} />
                <ImageContain src={logo4} alt="logo1" style={{ maxWidth: 500, maxHeight: 100, marginBottom: 50 }} />
                <ImageContain src={logo5} alt="logo1" style={{ maxWidth: 500, maxHeight: 100, marginBottom: 50 }} />
                <ImageContain src={logo6} alt="logo1" style={{ maxWidth: 500, maxHeight: 100, marginBottom: 50 }} />
                <ImageContain src={logo7} alt="logo1" style={{ maxWidth: 500, maxHeight: 100, marginBottom: 50 }} />
                <ImageContain src={logo8} alt="logo1" style={{ maxWidth: 500, maxHeight: 100, marginBottom: 50 }} />
                <ImageContain src={logo9} alt="logo1" style={{ maxWidth: 500, maxHeight: 100, marginBottom: 50 }} />
                <ImageContain src={logo10} alt="logo1" style={{ maxWidth: 500, maxHeight: 100, marginBottom: 50 }} />
                <ImageContain src={logo11} alt="logo1" style={{ maxWidth: 500, maxHeight: 100, marginBottom: 50 }} />
                <ImageContain src={logo12} alt="logo1" style={{ maxWidth: 500, maxHeight: 100, marginBottom: 50 }} />

            </Section>
        </Container>
    </Layout>
)

export default NotFoundPage;
